<template>
  <!-- 柱状图 -->
  <div :id="id" class="chartsBox"></div>
</template>
<script>
import * as echarts from "echarts";
import { mapGetters } from 'vuex'
export default {
  props: {
    id: {
      type: String,
      default: "chartBar",
    },
    option: {
      type: Object,
      default: () => { },
      // {
      // isReplace:'false',//“替换”，
      //  title: '测试1',
      //  x: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun5"],
      //  unit: '',
      //  series: [
      //    {
      //      name: '线1',
      //      data:[150, 230, 224, 218, 135, 147, 222],
      //    },
      //    {
      //      name: '线2',
      //      data: [120, 130, 114, 118,35, 47, 111],
      //    }
      //  ],
      //  setSeries:{
      // barWidth: 15,
      //       showBackground: true, //带背景色
      //       backgroundStyle: {
      //         color: "rgba(180, 180, 180, 0.2)",
      //       },
      //       label: {//柱上显值
      //         show: true,
      //         position: "top",
      //       },
      //  }
      //};
    },
  },
  data() {
    return {
      chart: null,
      chartDom: null,
      defaultData: {
        isReplace: false,
        title: {
          text: "测试",
        },
        color: ["#0D64E7"],
        xAxis: {
          type: "category",
          triggerEvent: true,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          axisLabel: {
            textStyle: {
              color: '#7587A0',
              fontSize: 12
            }
          },
        },
        tooltip: {
          trigger: "axis",
        },
        yAxis: {
          type: "value",
          name: "",
          nameTextStyle: {
            padding: [0, 0, 0, 18],
          },
          axisLabel: {
            textStyle: {
              color: '#7587A0',
              fontSize: 12
            }
          },
        },
        grid: {
          x: 40,
          y: 40,
          x2: 15,
          y2: 25
        },
        toolbox: {
          // feature: {
          //   saveAsImage: {},
          // },
        },
        series: [],
      },
      timer: null,
      currentIndex: -1,
      isAnimation: false,//是否开启自动显示
    };
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
  },
  watch: {
    data() {
      this.initOption();
    },
  },
  mounted: function () {
    this.initOption();
  },
  methods: {
    handle() {
      if (this.option.isReplace) {
        this.defaultData = Object.assign(this.defaultData, this.option);
        return this.defaultData;
      }

      if (this.option.color != undefined && this.option.color.length > 0) {
        this.defaultData.color = this.option.color;
      }
      if (this.option.isAnimation != undefined) {
        this.isAnimation = this.option.isAnimation;
      }
      this.defaultData.xAxis.axisLabel.textStyle.color = this.option.xColor != undefined ? this.option.xColor : "#7587A0";
      this.defaultData.yAxis.axisLabel.textStyle.color = this.option.yColor != undefined ? this.option.yColor : "#7587A0";
      this.defaultData.series = [];
      this.defaultData.title.text = this.option.title;
      this.defaultData.yAxis.name =
        this.option.unit == "" ? "" : this.showLang('com.op.unit') + ":" + this.option.unit;
      this.defaultData.xAxis.data = this.option.x;
      let getSeries = {
        type: "bar",
        showBackground: true, //带背景色
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
        label: {
          show: true,
          position: "top",
        },
      };
      getSeries = Object.assign(getSeries, this.option.setSeries);
      this.option.series.forEach((el) => {
        el = Object.assign(el, getSeries);
        this.defaultData.series.push(el);
      });
      //无数据处理
      if (this.defaultData.xAxis.data.length == 0) {
        this.defaultData.yAxis.min = 0;
        this.defaultData.yAxis.max = 10;
      }
      return this.defaultData;
    },
    initOption() {
      let newPromise = new Promise((resolve) => {
        resolve();
      });
      newPromise.then(() => {
        this.chartDom = document.getElementById(this.id);
        if( this.chartDom==null) return ;
        this.chart = echarts.init(this.chartDom);
        this.chart.on('click', this.handlerClick);
        if (this.option) {
          this.chart.setOption(this.handle());
        }
        if (this.isAnimation) {
          this.chart.getZr().on('mousemove', ()=>{
            this.closeTooltip()
          })
          this.chart.getZr().on('mouseout', ()=>{
            this.startTooltioLoop()
          })
          this.startTooltioLoop();
        }
      });
    },
    handlerClick: function (params) {
      this.$emit('click', params);
    },
    switchToooltip() {
      this.chart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      })
      this.currentIndex = (this.currentIndex + 1) % this.defaultData.series[0].data.length;
      this.chart.dispatchAction({
        type: 'highlinght',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      })
      this.chart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      })
    },
    startTooltioLoop() {
      this.timer = setInterval(() => {
        this.switchToooltip();
      }, 2500);
    },
    closeTooltip() {
      clearInterval(this.timer);
      this.timer = undefined;
    },
  },
  beforeDestroy() {
    this.closeTooltip();
  },


};
</script>
<style scoped>
.chartsBox {
  width: 100%;
  height: 100%;
}
</style>

